import { ICreateLabelValues } from '../models/create-form.model';
import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export const initialLabelFormValues: ICreateLabelValues = {
    id: undefined,
    occasion: undefined,
    image: '',
    style: '',
    date: '',
    city: '',
    capacity: '500 ml',
    quantity: '',
    firstLine: '',
    secondLine: '',
    wishes: '',
    years: undefined,
};

const SMALL_MIN_QUANTITY = 36;
const NORMAL_MIN_QUANTITY = 45;
const MINT_MIN_QUANTITY = 15;
const LEMON_MIN_QUANTITY = 15;

export function getLabelFormSchema(t: TFunction<string | string[]>) {
    const minYear = new Date().getFullYear() - 125;
    const maxYear = new Date().getFullYear() + 25;

    return Yup.object().shape({
        occasion: Yup.string(),
        style: Yup.string().required(t('form.required.error')),
        date: Yup.string().when(['occasion', 'capacity'], {
            is: (occasion: string, capacity: string) =>
                occasion === 'birthday' && capacity !== '500 ml lemon',
            then: Yup.string()
                .test(
                    'min',
                    t('label.creator.year.error'),
                    (value) => value !== undefined && parseFloat(value) > minYear
                )
                .test(
                    'max',
                    t('label.creator.year.error'),
                    (value) => value !== undefined && parseFloat(value) <= maxYear
                )
                .required(t('form.required.error')),
            otherwise: Yup.string().required(t('form.required.error')),
        }),
        capacity: Yup.string().required(t('form.required.error')),
        quantity: Yup.number().test('quantity', (value, context) => {
            if (
                context.parent.capacity === '100 ml' &&
                (value === undefined || value < SMALL_MIN_QUANTITY)
            ) {
                return context.createError({ message: t('label.creator.quantity.small.hint') });
            }
            if (
                context.parent.capacity === '500 ml' &&
                (value === undefined || value < NORMAL_MIN_QUANTITY)
            ) {
                return context.createError({
                    message: t('label.creator.quantity.normal.hint'),
                });
            }
            if (
                context.parent.capacity === '500 ml mint' &&
                (value === undefined || value < MINT_MIN_QUANTITY)
            ) {
                return context.createError({ message: t('label.creator.quantity.mint.hint') });
            }
            if (
                context.parent.capacity === '500 ml lemon' &&
                (value === undefined || value < LEMON_MIN_QUANTITY)
            ) {
                return context.createError({ message: t('label.creator.quantity.lemon.hint') });
            }
            return true;
        }),
        firstLine: Yup.string()
            .max(16, t('label.creator.input.hint.first'))
            .required(t('form.required.error')),
        secondLine: Yup.string().when('occasion', {
            is: (occasion: string) => occasion === 'wedding' || occasion === 'anniversary',
            then: Yup.string()
                .min(3, t('form.required.error'))
                .max(14, t('label.creator.input.hint.second'))
                .required(t('form.required.error')),
            otherwise: Yup.string(),
        }),
        city: Yup.string().when(['occasion', 'capacity'], {
            is: (occasion: string, capacity: string) =>
                occasion === 'wedding' && capacity === '500 ml lemon',
            then: Yup.string().max(16, t('label.creator.city.hint')),
            otherwise: Yup.string(),
        }),
        wishes: Yup.string().when(['style', 'capacity'], {
            is: (style: string, capacity: string) => capacity === '100 ml' && style !== 'Folk',
            then: Yup.string().required(t('form.required.error')),
            otherwise: Yup.string(),
        }),
        years: Yup.number().when('occasion', {
            is: (occasion: string) => occasion === 'birthday' || occasion === 'anniversary',
            then: Yup.number()
                .min(1, t('label.creator.years.min.hint'))
                .max(150, t('label.creator.years.max.hint'))
                .required(t('form.required.error')),
            otherwise: Yup.number(),
        }),
    });
}
