import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

import { container, wrapper, button } from './label-floating-reminder.module.scss';

import pagesContext from '../../config/pages-context';
import { getUrlParamValue } from '../../utils/get-url-param-value';

interface ILabelFloatingReminderProps {
    className?: string;
    onClick?: () => void;
}

const reminderImg = '../../assets/images/floating-reminder.png';

const LabelFloatingReminder: React.FC<ILabelFloatingReminderProps> = ({
    className = '',
    onClick,
}) => {
    const style = getUrlParamValue('styl') || 'Klasyczny';

    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            navigate(`/${pagesContext.labelGenerator.slug}?styl=${style}&capacity=small`);
        }
    };

    return (
        <div className={`${className} ${container}`}>
            <div className={wrapper}>
                <StaticImage src={reminderImg} alt={'Podarunek dla gości'} />
                <button className={button} type={'button'} onClick={handleClick} />
            </div>
        </div>
    );
};

export default LabelFloatingReminder;
