import React, { useEffect, useRef } from 'react';
import { Field, useField } from 'formik';

import {
    select,
    selectOpen,
    selectActive,
    selectFilled,
    inputBlock,
    input,
    block,
    blockText,
    blockIcon,
    list,
    item,
    itemText,
    selectError,
} from './form-select.module.scss';
import AngleIcon from '../../assets/images/svg/angle.svg';

import { IWishes } from '../../models/wishes.model';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import FormInput from './form-input';

interface IFromSelect {
    id: string;
    name: string;
    placeholder?: string;
    wishes?: IWishes[];
    capacities?: string[];
    inputMode?: boolean;
    maxlength?: number;
    errorStyle?: boolean;
    setIsSwiped: (state: boolean) => void;
    occasion?: string;
    styleImageSizes?: {
        [p: string]: { capacity: string; id: number } | undefined;
    };
    replaceValueField?: string;
}

const FormSelect: React.FC<IFromSelect> = ({
    id,
    name,
    placeholder,
    wishes,
    inputMode = false,
    capacities,
    maxlength,
    errorStyle,
    setIsSwiped,
    occasion,
    styleImageSizes,
    replaceValueField = '',
}) => {
    const [open, setOpen] = React.useState(false);
    const [active, setActive] = React.useState(false);
    const [label, setLabel] = React.useState(placeholder);
    const [mainField, , mainHelpers] = useField(id);
    const [altField, , altHelpers] = useField(replaceValueField);
    const setMainValue = useRef(mainHelpers.setValue);
    const setAltValue = useRef(altHelpers.setValue);
    const selectRef = useRef<HTMLDivElement>(null);
    const { t } = useI18next();

    const capacitiesNames: { [key: string]: string } = {
        '100 ml': `${t('label.capacity.100')}`,
        '500 ml': `${t('label.capacity.500')}`,
        '500 ml lemon': `${t('label.capacity.lemon.500')}`,
        '500 ml mint': `${t('label.capacity.mint.500')}`,
    };

    if (!capacities?.includes('500 ml lemon')) {
        capacities?.push('500 ml lemon');
    }

    useEffect(() => {
        if (!inputMode) {
            let foundOptions, foundCapacities;

            if (wishes) foundOptions = wishes.find((item) => item.title === mainField.value);

            if (capacities)
                foundCapacities = capacities.find((capacity) => capacity === mainField.value);

            if (!(foundOptions || foundCapacities)) {
                setMainValue.current('');
            } else {
                if (foundOptions) setLabel(foundOptions.title);

                if (foundCapacities) {
                    setLabel(capacitiesNames[foundCapacities]);

                    if (styleImageSizes && !styleImageSizes[altField.value]) {
                        const availableLabels = Object.entries(styleImageSizes).filter(
                            (entry) => entry[1] !== undefined
                        );

                        if (availableLabels.length) {
                            const selectedLabel =
                                occasion !== 'birthday'
                                    ? availableLabels[0][0]
                                    : availableLabels[availableLabels.length - 1][0];
                            setAltValue.current(selectedLabel);
                        }
                    }
                }
            }
        }
    }, [inputMode, mainField.value, id, wishes]);

    useEffect(() => {
        setMainValue.current = mainHelpers.setValue;
    }, [mainHelpers.setValue]);

    useEffect(() => {
        if (mainField.value === '') setLabel(placeholder);
    }, [placeholder, mainField.value]);

    useEffect(() => {
        const handleDropdownClose = (event: any): void => {
            const select = selectRef?.current;
            if (!select) return;
            if (event.target === select || select.contains(event.target)) return;
            setOpen(false);
        };

        if (open) {
            document.addEventListener('click', handleDropdownClose);
        }

        return () => {
            document.removeEventListener('click', handleDropdownClose);
        };
    }, [open]);

    function handleBlockClick() {
        setActive(true);
        setOpen((prevState) => !prevState);
    }

    function handleItemClick(e: React.MouseEvent<HTMLButtonElement>, index: number) {
        if (!(e.target instanceof HTMLButtonElement)) {
            return;
        }

        if (wishes) mainHelpers.setValue(wishes[index].title, true);

        if (capacities) {
            mainHelpers.setValue(capacities[index], true);

            if (occasion === 'birthday') {
                setIsSwiped(false);
            }
        }

        setOpen(false);
    }

    return (
        <div
            className={`${select} ${open ? selectOpen : ''} 
                        ${label !== placeholder ? selectFilled : ''} 
                        ${active ? selectActive : ''} 
                        ${errorStyle ? selectError : ''}
                        `}
            ref={selectRef}
        >
            {inputMode ? (
                <div className={inputBlock}>
                    <FormInput
                        maxlength={maxlength}
                        className={input}
                        id={id}
                        name={name}
                        placeholder={placeholder}
                    />
                    <button className={block} type={'button'} onClick={handleBlockClick}>
                        <AngleIcon className={blockIcon} />
                    </button>
                </div>
            ) : (
                <>
                    <Field type={'hidden'} id={id} name={name} />
                    <button className={block} type={'button'} onClick={handleBlockClick}>
                        <span className={blockText}>{label}</span>
                        <AngleIcon className={blockIcon} />
                    </button>
                </>
            )}
            <div className={`${list}`}>
                {wishes?.map((option, index) => {
                    return (
                        <button
                            tabIndex={open ? 0 : -1}
                            className={item}
                            key={`select_${index}`}
                            onClick={(e) => {
                                handleItemClick(e, index);
                            }}
                            data-value={option.title}
                            type={'button'}
                        >
                            {renderOptionLines(wishes, index)}
                        </button>
                    );
                })}

                {capacities?.map((capacity, index) => {
                    return (
                        <button
                            tabIndex={open ? 0 : -1}
                            className={item}
                            key={`select_${index}`}
                            onClick={(e) => {
                                handleItemClick(e, index);
                            }}
                            data-value={capacity}
                            type={'button'}
                        >
                            {renderCapacities(capacities, index, capacitiesNames)}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

function renderOptionLines(wishes: IFromSelect['wishes'], index: number) {
    return (
        wishes && (
            <span key={`option-line-${wishes[index].title}`} className={itemText}>
                {wishes[index].title}
            </span>
        )
    );
}

function renderCapacities(
    capacities: IFromSelect['capacities'],
    index: number,
    capacitiesNames: { [key: string]: string }
) {
    return capacities && capacitiesNames[capacities[index]];
}

export default FormSelect;
