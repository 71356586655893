import { ICreateLabelValues } from '../models/create-form.model';
import {
    ICleanImage,
    ILabelStyleCreator,
    ILabelTexts,
    TLabelGeneratorCapacity,
} from '../models/label-style.model';
import React from 'react';

interface ILabelProps {
    context: CanvasRenderingContext2D;
    canvas: HTMLCanvasElement;
    labelTexts: ILabelTexts;
    imageDigits: HTMLImageElement[] | undefined;
    occasion: string;
    style: string;
    capacity: string;
    firstLine: string;
    dateLine: string;
    cityLine?: string;
    secondLine?: string | undefined;
    yearsLine?: number | undefined;
    yearsTextLine?: string | undefined;
    wishesLine?: string | undefined;
    textStyle: {
        fontFamily: string;
        fontSize: number | undefined;
        fontColor: string | undefined;
        secondFontColor?: string | undefined;
        additionalFontColor?: string | undefined;
        firstLineOffsetY: number | undefined;
        secondLineOffsetY?: number | undefined;
        dateFontSize: number | undefined;
        dateLineOffsetY: number | undefined;
        cityLineOffsetY?: number | undefined;
        yearsFontSize?: number | undefined;
        yearsAdditionalFontSize?: number | undefined;
        yearsLineOffsetY?: number | undefined;
        yearsOffsetX?: number | undefined;
        yearsTextOffsetX?: number | undefined;
        yearsTextFontSize?: number | undefined;
        wishesLineOffsetY?: number | undefined;
        fontWeight: number;
    };
}

export function reDrawLabel(
    savedLabel: ICreateLabelValues,
    cleanImages: Record<string, ICleanImage> | undefined,
    canvasRef: React.RefObject<HTMLCanvasElement>,
    occasion: string,
    labelStyles: ILabelStyleCreator[],
    newStyle: string,
    labelTexts: ILabelTexts,
    imageDigits: HTMLImageElement[] | undefined,
    isImageLoaded: boolean
) {
    const foundLabel = labelStyles.find((style) => style.type === newStyle);
    const foundCapacity = foundLabel?.capacities.find(
        (capacity) => capacity.capacity === savedLabel.capacity
    );

    let labelImage: string | undefined = '';

    if (foundLabel) {
        const drawLabelProps = {
            cleanImages: cleanImages,
            values: {
                ...savedLabel,
                style: newStyle,
            },
            occasion: occasion,
            canvasRef: canvasRef,
            savedLabel: foundLabel,
            isSmall: !foundCapacity,
            capacityType: foundCapacity ? foundCapacity.capacity : '500 ml',
            labelTexts: labelTexts,
            imageDigits: imageDigits,
            isImageLoaded: isImageLoaded,
        };

        drawLabel(drawLabelProps);
        labelImage = getDrawnLabel(canvasRef);
    }
    return labelImage;
}

export function getDrawnLabel(canvasRef: React.RefObject<HTMLCanvasElement>) {
    const canvas = canvasRef.current;

    if (canvas === null) return;
    return canvas.toDataURL();
}

export function drawLabel(props: {
    cleanImages: { [p: string]: ICleanImage } | undefined;
    values: ICreateLabelValues;
    occasion: string;
    canvasRef: React.RefObject<HTMLCanvasElement>;
    savedLabel: ILabelStyleCreator;
    isSmall: boolean;
    capacityType: TLabelGeneratorCapacity;
    labelTexts: ILabelTexts;
    imageDigits: HTMLImageElement[] | undefined;
    isImageLoaded: boolean;
}) {
    const {
        cleanImages,
        values,
        occasion,
        canvasRef,
        savedLabel,
        isSmall,
        capacityType,
        labelTexts,
        imageDigits,
        isImageLoaded,
    } = props;
    const canvas = canvasRef.current;

    if (!cleanImages) return false;

    if (canvas === null) return;
    const context = canvas.getContext('2d');

    if (context === null) return;

    const textStyle = getTextStyle(isSmall, capacityType, savedLabel);
    const image = getCleanImage(isSmall, capacityType, cleanImages[values.style]);

    if (!image) return false;

    drawEmptyLabel({
        context: context,
        canvasSource: image,
        occasion: occasion,
        canvas: canvas,
        capacity: values.capacity,
        isImageLoaded: isImageLoaded,
        callback: () => {
            drawTextOverLabel({
                context: context,
                canvas: canvas,
                occasion: occasion,
                labelTexts: labelTexts,
                style: values.style,
                capacity: values.capacity,
                firstLine: values.firstLine,
                secondLine: values.secondLine,
                dateLine: values.date,
                yearsLine: values.years,
                wishesLine: values.wishes,
                cityLine: values.city,
                imageDigits: imageDigits,
                textStyle: textStyle,
            });
        },
    });
}

function getTextStyle(
    isSmall: boolean,
    capacityType: TLabelGeneratorCapacity,
    savedLabel: ILabelStyleCreator
) {
    if (isSmall)
        return {
            fontFamily: savedLabel.fontFamily,
            fontSize: savedLabel.smallFontSize,
            fontColor: savedLabel.smallFontColor,
            additionalFontColor: savedLabel.additionalFontColor,
            firstLineOffsetY: savedLabel.smallFirstLineOffsetY,
            secondLineOffsetY: savedLabel.smallSecondLineOffsetY,
            dateFontSize: savedLabel.smallDateFontSize,
            dateLineOffsetY: savedLabel.smallDateLineOffsetY,
            wishesLineOffsetY: savedLabel.wishesLineOffsetY,
            fontWeight: 400,
        };
    else if (capacityType === '500 ml lemon')
        return {
            fontFamily: savedLabel.fontFamily,
            fontSize: savedLabel.lemonFontSize,
            fontColor: savedLabel.lemonFontColor,
            additionalFontColor: savedLabel.additionalFontColor,
            firstLineOffsetY: savedLabel.lemonFirstLineOffsetY,
            secondLineOffsetY: savedLabel.lemonSecondLineOffsetY,
            dateFontSize: savedLabel.lemonDateFontSize,
            dateLineOffsetY: savedLabel.lemonDateLineOffsetY,
            cityLineOffsetY: savedLabel.lemonCityLineOffsetY,
            yearsFontSize: savedLabel.lemonYearsFontSize,
            yearsAdditionalFontSize: savedLabel.lemonYearsAdditionalFontSize,
            yearsLineOffsetY: savedLabel.lemonYearsLineOffsetY,
            yearsOffsetX: savedLabel.lemonYearsOffsetX,
            yearsTextOffsetX: savedLabel.lemonYearsTextOffsetX,
            yearsTextFontSize: savedLabel.lemonYearsTextFontSize,
            fontWeight: 400,
        };
    else if (capacityType === '500 ml mint')
        return {
            fontFamily: savedLabel.fontFamily,
            fontSize: savedLabel.mintFontSize,
            fontColor: savedLabel.mintFontColor,
            additionalFontColor: savedLabel.mintAdditionalFontColor,
            firstLineOffsetY: savedLabel.mintFirstLineOffsetY,
            secondLineOffsetY: savedLabel.mintSecondLineOffsetY,
            dateFontSize: savedLabel.mintDateFontSize,
            dateLineOffsetY: savedLabel.mintDateLineOffsetY,
            yearsFontSize: savedLabel.mintYearsFontSize,
            yearsLineOffsetY: savedLabel.mintYearsLineOffsetY,
            yearsOffsetX: savedLabel.mintYearsOffsetX,
            yearsTextOffsetX: savedLabel.mintYearsTextOffsetX,
            yearsTextFontSize: savedLabel.mintYearsTextFontSize,
            fontWeight: 400,
        };

    return {
        fontFamily: savedLabel.fontFamily,
        fontSize: savedLabel.fontSize,
        fontColor: savedLabel.fontColor,
        secondFontColor: savedLabel.smallFontColor,
        additionalFontColor: savedLabel.additionalFontColor,
        firstLineOffsetY: savedLabel.firstLineOffsetY,
        secondLineOffsetY: savedLabel.secondLineOffsetY,
        dateFontSize: savedLabel.dateFontSize,
        dateLineOffsetY: savedLabel.dateLineOffsetY,
        yearsFontSize: savedLabel.yearsFontSize,
        yearsLineOffsetY: savedLabel.yearsLineOffsetY,
        yearsOffsetX: savedLabel.yearsOffsetX,
        yearsTextOffsetX: savedLabel.yearsTextOffsetX,
        yearsTextFontSize: savedLabel.yearsTextFontSize,
        fontWeight: 400,
    };
}

function getCleanImage(
    isSmall: boolean,
    capacityType: TLabelGeneratorCapacity,
    images: ICleanImage
) {
    if (isSmall) return images.smallImage;
    else if (capacityType === '500 ml lemon') return images.lemonImage;
    else if (capacityType === '500 ml mint') return images.mintImage;
    return images.image;
}

export function drawEmptyLabel(props: {
    context: CanvasRenderingContext2D;
    canvasSource: HTMLImageElement;
    canvas: HTMLCanvasElement;
    occasion: string;
    capacity: string;
    isImageLoaded: boolean;
    callback: () => void;
}) {
    const { context, canvasSource, canvas, capacity, isImageLoaded, callback } = props;
    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    const dimensions = getDimensions(capacity);

    if (isImageLoaded && canvasSource.height > 0) {
        context.shadowOffsetX = 2;
        context.shadowOffsetY = 2;
        context.shadowColor = 'rgba(0,0,0,.2)';
        context.shadowBlur = 10;

        context.drawImage(
            canvasSource,
            canvas.width / 2 - dimensions.width / 2,
            canvas.height / 2 - dimensions.height / 2,
            dimensions.width,
            dimensions.height
        );

        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;
        context.shadowBlur = 0;

        callback();
    }
}

function getDimensions(capacity: string) {
    if (capacity === '100 ml') {
        return {
            width: 395,
            height: 558,
        };
    } else if (capacity === '500 ml lemon') {
        return {
            width: 448,
            height: 660,
        };
    }
    return {
        width: 484,
        height: 660,
    };
}

export function drawTextOverLabel(props: ILabelProps) {
    drawFirstAndSecondLine(props);
    drawWishes(props);
    drawCity(props);
    drawDate(props);
    drawYears(props);
    drawYearsText(props);
}

function drawFirstAndSecondLine(props: ILabelProps) {
    const { style, capacity, textStyle, context, firstLine, secondLine, canvas, occasion } = props;

    // TODO - Dodać pole na LemonFontFamily
    // TODO - Dodać pole na firstLineOffsetX
    // TODO - Dodać pole na dateLineOffsetX

    const isLemon = capacity === '500 ml lemon';
    let fontWeight = textStyle.fontWeight;
    let fontFamily = textStyle.fontFamily;
    let additionalOffsetX = 0;

    if (occasion === 'anniversary') {
        if (style === 'Glamour' && isLemon) {
            fontFamily = 'GreatVibesRegular';
        }

        if (style === 'Vintage' && isLemon) {
            fontFamily = 'AlexBrush';
        }
    }

    if (style === 'Folk' && isLemon) {
        fontFamily = 'CaveatRegular';
    }

    if (style === 'Klasyczny' && isLemon) {
        fontFamily = 'MsMadiRegular';
    }

    if (style === 'Muzyczny' && isLemon) {
        fontFamily = 'Bangers';
    }

    if (style === 'Imprezowy' && isLemon) {
        fontWeight = 600;
        fontFamily = 'Avenir';
    }

    if (style === 'Odważny' && isLemon) {
        fontFamily = 'PoetsenOne';
    }

    context.textAlign = 'center';
    context.shadowColor = 'black';
    context.fillStyle = textStyle.fontColor || '#000000';
    context.font = `${fontWeight} ${textStyle.fontSize}px ${fontFamily}`;

    if (style === 'Komiksowy') {
        context.shadowOffsetY = 3;
        context.shadowOffsetX = 1;
        additionalOffsetX = -12;
    }

    if (style === 'Muzyczny') {
        if (isLemon) {
            additionalOffsetX = -70;
        } else {
            additionalOffsetX = -45;
            context.save();
            context.rotate(-(7 * Math.PI) / 180);
        }
    }

    if (style === 'Elegancki' && textStyle.fontColor && textStyle.additionalFontColor) {
        const gradient = context.createLinearGradient(130, 0, 350, 0);
        gradient.addColorStop(0, `${textStyle.additionalFontColor}`);
        gradient.addColorStop(0.5, `${textStyle.fontColor}`);
        gradient.addColorStop(1, `${textStyle.additionalFontColor}`);
        context.fillStyle = gradient;
        context.shadowOffsetY = 1;
        context.shadowColor = textStyle.fontColor;
    }

    if (style === 'Imprezowy' && !isLemon) {
        context.shadowOffsetY = 1;
        context.shadowColor = '#34190E';

        const gradient = context.createLinearGradient(180, 0, 320, 0);
        gradient.addColorStop(0, `${textStyle.additionalFontColor}`);
        gradient.addColorStop(0.5, `${textStyle.fontColor}`);
        gradient.addColorStop(1, `${textStyle.additionalFontColor}`);
        context.fillStyle = gradient;
    }

    if (style === 'Odważny') {
        context.shadowOffsetY = 1;

        if (isLemon) {
            additionalOffsetX = 25;
        }
    }

    context.fillText(
        style === 'Odważny' && isLemon ? firstLine.toUpperCase() : firstLine,
        canvas.width / 2 + additionalOffsetX,
        canvas.height * (textStyle.firstLineOffsetY || 0.5)
    );

    context.fillText(
        secondLine || '',
        canvas.width / 2,
        canvas.height * (textStyle.secondLineOffsetY || 0.65)
    );

    context.restore();
    context.textAlign = 'center';
    context.shadowColor = 'black';
    context.fillStyle = textStyle.fontColor || '#000000';
    context.shadowOffsetX = 0;
    context.shadowOffsetY = 0;
}

function drawWishes(props: ILabelProps) {
    const { style, capacity, textStyle, context, wishesLine, canvas } = props;

    if (textStyle.wishesLineOffsetY && wishesLine && capacity === '100 ml') {
        let fontSize = textStyle.dateFontSize;

        if (style === 'Klasyczny' && textStyle.dateFontSize) {
            fontSize = textStyle.dateFontSize - 6;
        }
        context.font = `${textStyle.fontWeight} ${fontSize}px ${textStyle.fontFamily}`;

        context.fillText(wishesLine, canvas.width / 2, canvas.height * textStyle.wishesLineOffsetY);
    }
}

function drawCity(props: ILabelProps) {
    const { style, textStyle, context, cityLine, canvas } = props;
    let fontFamily = textStyle.fontFamily;
    let fontSize = textStyle.dateFontSize;

    if (cityLine && textStyle.cityLineOffsetY && textStyle.dateFontSize) {
        if (fontSize) {
            if (style === 'Glamour') {
                fontSize += 2;
            }
            if (style === 'Rustykalny' || style === 'Boho') {
                fontSize -= 2;
            }
        }

        if (style === 'Rustykalny' && textStyle.additionalFontColor) {
            context.fillStyle = textStyle.additionalFontColor;
        }

        if (style === 'Folk') {
            fontFamily = 'CaveatRegular';
        }

        if (style === 'Klasyczny') {
            fontFamily = 'MsMadiRegular';
        }

        context.font = `${textStyle.fontWeight} ${fontSize}px ${fontFamily}`;
        context.fillText(cityLine, canvas.width / 2, canvas.height * textStyle.cityLineOffsetY);

        context.fillStyle = textStyle.fontColor || '#000000';
    }
}

function drawDate(props: ILabelProps) {
    const { style, capacity, textStyle, context, canvas, occasion } = props;
    const isLemon = capacity === '500 ml lemon';
    const isMint = capacity === '500 ml mint';
    let fontFamily = textStyle.fontFamily;
    let fontWeight = textStyle.fontWeight;
    let dateOffsetX = 0;
    let removeLetter = false;

    if (textStyle.dateLineOffsetY && props.dateLine) {
        const dateLine =
            occasion === 'birthday' && capacity !== '500 ml lemon'
                ? `${props.dateLine.toString().slice(0, 4)} r.`
                : props.dateLine;

        if (occasion === 'anniversary') {
            if (style === 'Vintage') {
                if (textStyle.additionalFontColor && isMint) {
                    context.fillStyle = textStyle.additionalFontColor;
                }
                dateOffsetX = isLemon ? -100 : 10;
                removeLetter = isLemon;
            } else if (style === 'Romantyczny') {
                dateOffsetX = isLemon ? -60 : 0;
            } else {
                if (textStyle.additionalFontColor && isMint) {
                    context.fillStyle = textStyle.additionalFontColor;
                }
                dateOffsetX = isLemon ? -127 : 0;
                removeLetter = isLemon;
                fontFamily = isLemon ? 'GreatVibesRegular' : textStyle.fontFamily;
            }
        }

        if (style === 'Folk' && isLemon) {
            fontFamily = 'CaveatRegular';
        }

        if (style === 'Klasyczny' && isLemon) {
            fontFamily = 'MsMadiRegular';
        }

        if (style === 'Komiksowy') {
            if (isMint) {
                dateOffsetX = 50;
            } else {
                dateOffsetX = 133;
            }
        }

        if (style === 'Muzyczny') {
            if (isMint) {
                dateOffsetX = -32;
            } else if (isLemon) {
                dateOffsetX = -75;
            } else {
                dateOffsetX = 45;
            }

            if (isLemon) {
                fontFamily = 'Bangers';
            } else {
                context.save();
                context.rotate(-(6 * Math.PI) / 180);
            }
        }

        if (style === 'Elegancki' && textStyle.fontColor && textStyle.additionalFontColor) {
            const gradient = context.createLinearGradient(210, 0, 270, 0);
            gradient.addColorStop(0, `${textStyle.additionalFontColor}`);
            gradient.addColorStop(0.5, `${textStyle.fontColor}`);
            gradient.addColorStop(1, `${textStyle.additionalFontColor}`);
            context.fillStyle = gradient;
            context.shadowOffsetY = 1;
            context.shadowColor = textStyle.fontColor;
        }

        if (style === 'Imprezowy' && textStyle.additionalFontColor) {
            if (isLemon) {
                fontFamily = 'Avenir';
                fontWeight = 600;
            } else {
                const gradient = context.createLinearGradient(220, 0, 260, 0);
                gradient.addColorStop(0, `${textStyle.additionalFontColor}`);
                gradient.addColorStop(0.7, `${textStyle.fontColor}`);
                gradient.addColorStop(1, `${textStyle.additionalFontColor}`);
                context.fillStyle = gradient;
                context.shadowOffsetY = 1;
                context.shadowColor = '#34190E';
            }
        }

        if (style === 'Odważny' && textStyle.additionalFontColor && !isMint && !isLemon) {
            context.fillStyle = textStyle.additionalFontColor;
        }

        if (style === 'Odważny' && isLemon) {
            fontFamily = 'PoetsenOne';
            dateOffsetX = -10;
        }

        context.font = `${fontWeight} ${textStyle.dateFontSize}px ${fontFamily}`;

        context.fillText(
            removeLetter ? dateLine.replace('r.', '') : dateLine,
            canvas.width / 2 + dateOffsetX,
            canvas.height * textStyle.dateLineOffsetY
        );

        context.restore();
        context.textAlign = 'center';
        context.shadowColor = 'black';
        context.fillStyle = textStyle.fontColor || '#000000';
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;
    }
}

function drawYears(props: ILabelProps) {
    const { style, capacity, textStyle, context, occasion, canvas, imageDigits } = props;
    const isLemon = capacity === '500 ml lemon';
    const isMint = capacity === '500 ml mint';

    if (textStyle.yearsLineOffsetY && props.yearsLine) {
        const yearsLine = props.yearsLine.toString().slice(0, 3);
        const digits = yearsLine.split('');
        let addExclamation = false;
        let fontWeight = textStyle.fontWeight;
        let fontFamily = textStyle.fontFamily;

        if (occasion === 'anniversary') {
            if (style === 'Glamour') {
                fontWeight = isLemon ? 400 : 800;
                fontFamily = isLemon ? 'GreatVibesRegular' : textStyle.fontFamily;

                if (isLemon) {
                    context.fillStyle = '#95C13D';
                } else if (textStyle.additionalFontColor) {
                    context.fillStyle = textStyle.additionalFontColor;
                }
            } else if (style === 'Romantyczny') {
                if (textStyle.additionalFontColor && textStyle.fontColor && isLemon) {
                    context.fillStyle = textStyle.additionalFontColor;
                    context.shadowColor = textStyle.fontColor;
                }
                context.shadowOffsetY = 2;
            } else {
                if (textStyle.additionalFontColor && !isLemon) {
                    context.fillStyle = textStyle.additionalFontColor;
                }

                if (isLemon) {
                    addExclamation = true;
                    context.fillStyle = '#95C13D';
                    context.save();
                    context.rotate(-(6 * Math.PI) / 180);
                }
            }
        }

        if (occasion === 'birthday') {
            addExclamation =
                capacity !== '500 ml lemon' || (capacity === '500 ml lemon' && style === 'Odważny');

            if (style === 'Komiksowy') {
                context.shadowOffsetX = 1;
                context.shadowOffsetY = 4;
                context.shadowColor = 'black';
            } else if (style === 'Muzyczny') {
                if (!isLemon) {
                    context.shadowOffsetX = 1;
                    context.shadowOffsetY = 4;
                    context.fillStyle = textStyle.additionalFontColor || '#000';
                }

                if (isMint) {
                    context.shadowColor = '#000';
                } else {
                    context.shadowColor = textStyle.fontColor || '#000';
                }

                if (isLemon) {
                    fontFamily = 'Bangers';
                }
            } else if (
                style === 'Elegancki' &&
                textStyle.fontColor &&
                textStyle.additionalFontColor
            ) {
                const gradient = context.createLinearGradient(100, 0, 380, 0);
                gradient.addColorStop(0, `${textStyle.fontColor}`);
                gradient.addColorStop(0.4, `${textStyle.additionalFontColor}`);
                gradient.addColorStop(0.7, `${textStyle.additionalFontColor}`);
                gradient.addColorStop(0.8, `${textStyle.fontColor}`);
                gradient.addColorStop(1, `${textStyle.additionalFontColor}`);
                context.fillStyle = gradient;
                context.shadowOffsetY = 3;
                context.shadowOffsetX = 1;
                context.shadowColor = '#855846';
            }

            if (style === 'Imprezowy' && imageDigits) {
                const firstLetterOffsetX = isLemon ? -90 : -115;
                const secondLetterOffsetX = isLemon ? -25 : -13;
                const width = isLemon ? 65 : 100;
                const height = isLemon ? 100 : 130;

                digits.forEach((digit, index) => {
                    if (index === 0 && textStyle.yearsLineOffsetY) {
                        context.drawImage(
                            imageDigits[parseFloat(digit)],
                            canvas.width / 2 + firstLetterOffsetX,
                            canvas.height * textStyle.yearsLineOffsetY,
                            width,
                            height
                        );
                    } else if (index === 1 && textStyle.yearsLineOffsetY) {
                        context.drawImage(
                            imageDigits[parseFloat(digit)],
                            canvas.width / 2 + secondLetterOffsetX,
                            canvas.height * textStyle.yearsLineOffsetY,
                            width,
                            height
                        );
                    }
                    return false;
                });

                if (!isLemon) {
                    context.drawImage(
                        imageDigits[10],
                        canvas.width / 2 + 88,
                        canvas.height * textStyle.yearsLineOffsetY,
                        44,
                        130
                    );
                }
            }
        }

        if (style === 'Odważny' && textStyle.additionalFontColor) {
            if (isLemon) {
                context.fillStyle = '#A1CE56';
                fontFamily = 'PoetsenOne';
            } else {
                const stopColor = isMint ? '#E9D49D' : '#fff';
                const gradient = context.createLinearGradient(-90, -600, canvas.width + 115, 0);
                gradient.addColorStop(0, `${textStyle.additionalFontColor}`);
                gradient.addColorStop(0.81, `${textStyle.additionalFontColor}`);
                gradient.addColorStop(0.84, stopColor);
                gradient.addColorStop(0.87, `${textStyle.additionalFontColor}`);
                gradient.addColorStop(0.89, stopColor);
                gradient.addColorStop(0.92, `${textStyle.additionalFontColor}`);
                gradient.addColorStop(0.95, stopColor);
                gradient.addColorStop(0.98, `${textStyle.additionalFontColor}`);
                gradient.addColorStop(1, `${textStyle.additionalFontColor}`);
                context.fillStyle = gradient;
                context.shadowOffsetY = 2;
                context.shadowColor = '#454140';
            }
        }

        if (style !== 'Imprezowy') {
            context.font = `${fontWeight} ${textStyle.yearsFontSize}px ${fontFamily}`;
            context.fillText(
                yearsLine.toString() + (addExclamation ? '!' : ''),
                canvas.width / 2 + (textStyle.yearsOffsetX || 0),
                canvas.height * textStyle.yearsLineOffsetY
            );
        }

        context.restore();
        context.fillStyle = textStyle.fontColor || '#000000';
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;
        context.shadowColor = 'black';
        context.font = `${textStyle.fontWeight} ${textStyle.yearsTextFontSize}px ${textStyle.fontFamily}`;
    }
}

function drawYearsText(props: ILabelProps) {
    const { style, capacity, textStyle, context, occasion, yearsLine, canvas, labelTexts } = props;
    const isLemon = capacity === '500 ml lemon';
    const isMint = capacity === '500 ml mint';

    if (yearsLine && textStyle.yearsLineOffsetY) {
        const digits = yearsLine.toString().split('');
        const lastDigit = digits[digits.length - 1];
        let firstWord = labelTexts.years.displayName;
        const secondWord = labelTexts.together.displayName;

        if (
            (lastDigit === '2' || lastDigit === '3' || lastDigit === '4') &&
            yearsLine.toString() !== '12' &&
            yearsLine.toString() !== '13' &&
            yearsLine.toString() !== '14'
        ) {
            firstWord = labelTexts.years.displayAltName;
        }

        if (occasion === 'anniversary') {
            if (style === 'Romantyczny') {
                context.fillText(
                    firstWord + ' ' + secondWord,
                    canvas.width / 2 + (textStyle.yearsTextOffsetX || 0),
                    canvas.height * (textStyle.yearsLineOffsetY + (isLemon ? 0.035 : 0))
                );
            } else if (style === 'Glamour') {
                const fontWeight = isLemon ? 400 : 800;
                const fontFamily = isLemon ? 'GreatVibesRegular' : textStyle.fontFamily;
                context.font = `${fontWeight} ${textStyle.yearsTextFontSize}px ${fontFamily}`;

                if (isLemon) {
                    context.fillStyle = '#95C13D';

                    context.fillText(
                        firstWord,
                        canvas.width / 2 + (textStyle.yearsTextOffsetX || 0),
                        canvas.height * textStyle.yearsLineOffsetY
                    );

                    if (textStyle.yearsTextFontSize) {
                        context.font = `${fontWeight} ${
                            textStyle.yearsTextFontSize - 20
                        }px ${fontFamily}`;
                    }

                    context.fillText(
                        secondWord,
                        canvas.width / 2 + (textStyle.yearsTextOffsetX || 0) + 87,
                        canvas.height * textStyle.yearsLineOffsetY
                    );
                } else {
                    if (textStyle.additionalFontColor) {
                        context.fillStyle = textStyle.additionalFontColor;
                        context.fillText(
                            firstWord + ' ' + secondWord,
                            canvas.width / 2 + (textStyle.yearsTextOffsetX || 0),
                            canvas.height * (textStyle.yearsLineOffsetY + 0.045)
                        );
                    }
                }
            } else {
                if (textStyle.yearsTextOffsetX && textStyle.additionalFontColor) {
                    let firstWordOffsetTop = -0.035;
                    let secondWordOffsetTop = -0.012;
                    const secondWordOffsetX = isLemon ? -33 : 0;
                    context.fillStyle = textStyle.additionalFontColor;

                    if (isLemon) {
                        firstWordOffsetTop = -0.065;
                        secondWordOffsetTop = 0.015;
                        context.fillStyle = '#95C13D';

                        context.save();
                        context.rotate((4 * Math.PI) / 180);
                        context.font = `${textStyle.fontWeight} ${textStyle.yearsFontSize}px ${textStyle.fontFamily}`;
                    }

                    if (isMint) {
                        firstWordOffsetTop = -0.055;
                        secondWordOffsetTop = -0.03;
                    }

                    context.fillText(
                        firstWord,
                        canvas.width / 2 + textStyle.yearsTextOffsetX,
                        canvas.height * (textStyle.yearsLineOffsetY + firstWordOffsetTop)
                    );

                    context.restore();

                    if (textStyle.yearsTextFontSize && textStyle.fontColor) {
                        if (isLemon) {
                            context.fillStyle = textStyle.fontColor;
                            context.font = `${textStyle.fontWeight} ${
                                textStyle.yearsTextFontSize - 6
                            }px ${textStyle.fontFamily}`;
                        } else {
                            context.font = `${textStyle.fontWeight} ${
                                textStyle.yearsTextFontSize / 2
                            }px ${textStyle.fontFamily}`;
                        }
                    }

                    context.fillText(
                        secondWord,
                        canvas.width / 2 + textStyle.yearsTextOffsetX + secondWordOffsetX,
                        canvas.height * (textStyle.yearsLineOffsetY + secondWordOffsetTop)
                    );
                }
            }
        } else if (occasion === 'birthday' && isLemon && style !== 'Odważny') {
            const textOffsetY = style === 'Imprezowy' ? 0.125 : -0.01;

            if (textStyle.yearsTextOffsetX) {
                if (style === 'Muzyczny') {
                    textStyle.fontFamily = 'Bangers';
                }

                if (style === 'Imprezowy') {
                    const gradient = context.createLinearGradient(650, -30, 680, 0);
                    gradient.addColorStop(0, `${textStyle.additionalFontColor}`);
                    gradient.addColorStop(0.5, `#F2DCB6`);
                    gradient.addColorStop(1, `${textStyle.additionalFontColor}`);
                    context.fillStyle = gradient;
                    context.shadowOffsetY = 1;
                    context.shadowColor = '#916527';
                    textStyle.fontFamily = 'AvenirCondensed';
                }

                context.font = `${textStyle.fontWeight} ${textStyle.yearsTextFontSize}px ${textStyle.fontFamily}`;

                context.fillText(
                    style === 'Imprezowy' ? firstWord.toUpperCase() : firstWord,
                    canvas.width / 2 + textStyle.yearsTextOffsetX,
                    canvas.height * (textStyle.yearsLineOffsetY + textOffsetY)
                );
            }
        }
    }
}
