import React from 'react';
import { getImage } from 'gatsby-plugin-image';

import { wrapper } from './saved-labels.module.scss';

import { ICreateLabelValues } from '../../models/create-form.model';
import { ILabelStyleCreator } from '../../models/label-style.model';

import SavedLabel from '../molecules/saved-label';

interface ISavedLabels {
    labels: ICreateLabelValues[];
    onRemoveSavedLabel: (index: number) => void;
    onEditSavedLabel: (index: number) => void;
    labelStyles: ILabelStyleCreator[];
}

const SavedLabels: React.FC<ISavedLabels> = ({
    labels,
    onRemoveSavedLabel,
    onEditSavedLabel,
    labelStyles,
}) => {
    return (
        <div className={wrapper}>
            {labels.map((label, index) => {
                const foundLabel = labelStyles.find((style) => style.type === label.style);
                const foundCapacity = foundLabel?.capacities.find(
                    (capacity) => capacity.capacity === label.capacity
                );
                if (!foundLabel) return null;

                let imageData, imageAlt;

                if (!foundCapacity && foundLabel.smallBottleImage) {
                    imageData = getImage(foundLabel.smallBottleImage.localFile);
                    imageAlt = foundLabel.smallBottleImage.alternativeText;
                } else {
                    if (foundCapacity?.capacity === '500 ml lemon' && foundLabel.lemonBottleImage) {
                        imageData = getImage(foundLabel.lemonBottleImage.localFile);
                        imageAlt = foundLabel.lemonBottleImage.alternativeText;
                    } else if (
                        foundCapacity?.capacity === '500 ml mint' &&
                        foundLabel.mintBottleImage
                    ) {
                        imageData = getImage(foundLabel.mintBottleImage.localFile);
                        imageAlt = foundLabel.mintBottleImage.alternativeText;
                    } else {
                        imageData = getImage(foundLabel.bottleImage.localFile);
                        imageAlt = foundLabel.bottleImage.alternativeText;
                    }
                }

                const labelType = foundCapacity ? 'normal' : 'small';

                return (
                    <SavedLabel
                        key={`saved_label_${index}`}
                        type={labelType}
                        imageData={imageData}
                        imageAlt={imageAlt}
                        style={label.style}
                        capacity={label.capacity}
                        firstLine={label.firstLine}
                        quantity={label.quantity}
                        secondLine={label.secondLine}
                        wishes={label.wishes}
                        occasion={label.occasion}
                        years={label.years}
                        index={index}
                        onRemoveSavedLabel={onRemoveSavedLabel}
                        onEditSavedLabel={onEditSavedLabel}
                    />
                );
            })}
        </div>
    );
};

export default SavedLabels;
