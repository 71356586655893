// extracted by mini-css-extract-plugin
export var backgroundAnimation = "saved-label-module--backgroundAnimation--acd94";
export var button = "saved-label-module--button--6d71a";
export var buttonContainer = "saved-label-module--button-container--0bea7";
export var contentContainer = "saved-label-module--content-container--fbf5e";
export var errorBlink = "saved-label-module--error-blink--3df82";
export var image = "saved-label-module--image--d995f";
export var imageContainer = "saved-label-module--image-container--96d82";
export var imageRatio = "saved-label-module--image-ratio--79ebd";
export var item = "saved-label-module--item--adbec";
export var text = "saved-label-module--text--52cb0";
export var textContainer = "saved-label-module--text-container--cfd3c";
export var textContainerWrapper = "saved-label-module--text-container-wrapper--cecd5";
export var textQuantity = "saved-label-module--textQuantity--7065d";
export var textStyle = "saved-label-module--text-style--66016";