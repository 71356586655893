import React, { useEffect } from 'react';
import DatePicker from 'react-date-picker';
import { useField } from 'formik';

import { datePickerWrapper, datePickerError } from './form-date-picker.module.scss';

import CalendarIcon from '../../assets/images/svg/calendar.svg';

interface IFormDatePicker {
    id: string;
    errorStyle?: boolean;
    format?: 'DMY' | 'YMD' | 'custom';
    customFormat?: (datePicked: Date) => string;
    initValue?: Date | null;
    minDate?: Date;
}

const FormDatePicker: React.FC<IFormDatePicker> = ({
    id,
    errorStyle = false,
    format = 'DMY',
    customFormat = (datePicked: Date) => {
        return datePicked.toLocaleDateString();
    },
    initValue = null,
    minDate = new Date(),
}) => {
    const [value, setValue] = React.useState<null | Date>(initValue);
    const [, , helpers] = useField(id);

    useEffect(() => {
        if (initValue) helpers.setValue(reformatDate(format, initValue, customFormat));
    }, []);

    useEffect(() => {
        setValue(initValue);
    }, [initValue]);

    return (
        <div className={`${datePickerWrapper} ${errorStyle ? datePickerError : ''}`}>
            <DatePicker
                minDetail={'year'}
                calendarIcon={<CalendarIcon />}
                minDate={!!minDate.getTime() ? minDate : new Date()}
                maxDate={new Date('2100-12-31')}
                onChange={(datePicked: Date) => {
                    setValue(datePicked);
                    if (datePicked !== null) {
                        helpers.setValue(reformatDate(format, datePicked, customFormat));
                    }
                }}
                value={value}
                format={'dd.MM.yyyy'}
            />
        </div>
    );
};

function reformatDate(
    format: string,
    datePicked: Date,
    customFormat: (datePicked: Date) => string
) {
    switch (format) {
        case 'custom':
            return customFormat(datePicked);

        case 'DMY':
            return dateFormatDMY(datePicked);

        case 'YMD':
            return dateFormatYMD(datePicked);

        default:
            return dateFormatDMY(datePicked);
    }
}

function dateFormatYMD(datePicked: Date) {
    return `${datePicked.getFullYear()}-${('0' + (datePicked.getMonth() + 1)).slice(-2)}-${(
        '0' + datePicked.getDate()
    ).slice(-2)}`;
}

function dateFormatDMY(datePicked: Date) {
    return `${('0' + datePicked.getDate()).slice(-2)}.${('0' + (datePicked.getMonth() + 1)).slice(
        -2
    )}.${datePicked.getFullYear()}`;
}

export default FormDatePicker;
