// extracted by mini-css-extract-plugin
export var backgroundAnimation = "label-generator-form-module--backgroundAnimation--b0747";
export var buttonsWrapper = "label-generator-form-module--buttons-wrapper--62810";
export var capacityArea = "label-generator-form-module--capacity-area--50291";
export var dateArea = "label-generator-form-module--date-area--cf41e";
export var errorBlink = "label-generator-form-module--error-blink--507ae";
export var fieldWrapper = "label-generator-form-module--field-wrapper--9ed68";
export var firstLineArea = "label-generator-form-module--first-line-area--29df2";
export var formLayout = "label-generator-form-module--form-layout--5527b";
export var labelArrow = "label-generator-form-module--label-arrow--80c44";
export var labelArrowPrev = "label-generator-form-module--label-arrow-prev--dc8a0";
export var labelButton = "label-generator-form-module--label-button--e4463";
export var labelButtons = "label-generator-form-module--label-buttons--37f2c";
export var quantityArea = "label-generator-form-module--quantity-area--f7523";
export var saveButton = "label-generator-form-module--saveButton--70035";
export var secondLineArea = "label-generator-form-module--second-line-area--10894";
export var styleArea = "label-generator-form-module--style-area--6949c";
export var styleAreaSlider = "label-generator-form-module--style-area-slider--63f3e";
export var styleWrapper = "label-generator-form-module--style-wrapper--a1c67";
export var styleWrapperSlider = "label-generator-form-module--style-wrapper-slider--d5c30";
export var swiped = "label-generator-form-module--swiped--8cb28";
export var wishesArea = "label-generator-form-module--wishes-area--c61ec";
export var yearsArea = "label-generator-form-module--years-area--8b155";