export const formatStringToDate = (date: string | null) => {
    if (!date) return null;

    const dateString = date.slice(0, -2);
    const [day, month, year] = dateString.split('.');

    if (!day || !month || !year) return null;

    return new Date(+year, +month - 1, +day);
};
