import axios from 'axios';

import { ILabelSummaryFormValues } from '../models/summary-form.model';
import { ICreateLabelValues } from '../models/create-form.model';
import prependApiUrl from '../utils/prepend-api-url';

export const postLabelCreate = (
    summaryForm: ILabelSummaryFormValues & { labels: ICreateLabelValues[] }
) => {
    return axios.post(prependApiUrl('/labels/create'), {
        ...summaryForm,
    });
};

export const getLabelsCampaignSettings = () => {
    return axios.get(prependApiUrl(`/labels/campaign`), {});
};
