import { useState } from 'react';
import { ICreateLabelValues } from '../models/create-form.model';
import { isBrowser } from '../utils/is-browser';

export const useSavedLabels = () => {
    const [savedLabels, setSavedLabels] = useState(getSavedLabels());

    const savedLabelsCount = savedLabels?.length;

    const removeSavedLabel = (index: number) => {
        return new Promise<void>((resolve) => {
            setSavedLabels((prevState: ICreateLabelValues[]) => {
                const newState = [...prevState.slice(0, index), ...prevState.slice(index + 1)];
                window.localStorage.setItem('savedLabels', JSON.stringify(newState));
                return newState;
            });
            resolve();
        });
    };

    const addNewSavedLabel = (labelValues: ICreateLabelValues, labelImage: string | undefined) => {
        setSavedLabels((prevState: ICreateLabelValues[]) => {
            let newState;
            const newLabel = labelValues;
            newLabel.image = labelImage;
            if (labelValues.id !== undefined) {
                const index = prevState.findIndex((label) => {
                    return label.id === labelValues.id;
                });
                newState = prevState;
                newState[index] = newLabel;
            } else {
                newLabel.id = Date.now();
                newState = [...prevState, newLabel];
            }
            window.localStorage.setItem('savedLabels', JSON.stringify(newState));
            return newState;
        });
    };

    const saveSavedLabels = (newLabels: ICreateLabelValues[]) => {
        setSavedLabels(() => {
            window.localStorage.setItem('savedLabels', JSON.stringify(newLabels));
            return newLabels;
        });
    };

    return {
        savedLabels,
        removeSavedLabel,
        addNewSavedLabel,
        savedLabelsCount,
        saveSavedLabels,
    };
};

const getSavedLabels = () => {
    const defaultPreset: ICreateLabelValues[] = [];

    if (!isBrowser()) {
        return defaultPreset;
    }

    const preset = window.localStorage.getItem('savedLabels');

    if (!preset) {
        return defaultPreset;
    }

    return JSON.parse(preset);
};
