import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    button,
    buttonContainer,
    contentContainer,
    image,
    imageContainer,
    imageRatio,
    item,
    text,
    textContainer,
    textStyle,
    textQuantity,
    textContainerWrapper,
} from './saved-label.module.scss';

import { IGatsbyImageData } from 'gatsby-plugin-image';
import { TFunction } from 'react-i18next';

import RatioImage from '../atoms/ratio-image';
import EditIcon from '../../assets/images/svg/edit.svg';
import RemoveIcon from '../../assets/images/svg/remove.svg';

interface ISavedLabel {
    imageData: IGatsbyImageData | undefined;
    type: 'normal' | 'small';
    imageAlt: string;
    style: string;
    capacity: string;
    firstLine: string;
    quantity: string | number;
    secondLine: string | undefined;
    index: number;
    onRemoveSavedLabel: (index: number) => void;
    onEditSavedLabel: (index: number) => void;
    wishes: string;
    occasion: string | undefined;
    years: number | undefined;
}

const SavedLabel: React.FC<ISavedLabel> = ({
    imageData,
    type = 'normal',
    imageAlt,
    style,
    capacity,
    firstLine,
    quantity,
    secondLine,
    index,
    onEditSavedLabel,
    onRemoveSavedLabel,
    wishes,
    occasion,
    years,
}) => {
    const { t } = useI18next();
    return (
        <div className={item}>
            <div className={imageContainer}>
                <RatioImage
                    className={image}
                    ratioClass={imageRatio}
                    image={imageData}
                    alt={imageAlt}
                    objectFit="scale-down"
                />
            </div>
            <div className={contentContainer}>
                <div className={textContainer}>
                    <div className={textContainerWrapper}>
                        <span className={`${text} ${textStyle}`}>
                            {`${t('saved.label.style.label')} ${style}`}
                        </span>
                        <span className={text}>
                            {`${t('label.creator.bottle')} ${getCapacityName(capacity, t)}`}
                        </span>
                        <span className={`${text} ${textQuantity}`}>
                            {`${t('label.creator.quantity')} ${quantity} ${t(
                                'saved.label.style.quantity'
                            )}`}
                        </span>
                    </div>
                    <div className={textContainerWrapper}>
                        {occasion === 'birthday' ? (
                            <span className={`${text} ${textStyle}`}>
                                {t('saved.label.style.name')} {firstLine}
                            </span>
                        ) : (
                            <>
                                <span className={`${text} ${textStyle}`}>
                                    {t('saved.label.style.names')}
                                </span>
                                <span className={text}>{firstLine}</span>
                            </>
                        )}
                        {occasion !== 'birthday' && <span className={text}>{secondLine}</span>}
                        {type === 'small' && style !== 'Folk' && (
                            <>
                                <span className={`${text} ${textStyle}`}>
                                    {t('saved.label.style.wishes')}
                                </span>
                                <span className={`${text}`}>{wishes}</span>
                            </>
                        )}
                        {occasion !== 'wedding' && (
                            <>
                                <span className={`${text}`}>
                                    {occasion === 'birthday'
                                        ? t('saved.label.style.years.birthday')
                                        : t('saved.label.style.years.anniversary')}{' '}
                                    {years}
                                </span>
                            </>
                        )}
                    </div>
                </div>
                <div className={buttonContainer}>
                    <button
                        className={button}
                        onClick={() => {
                            onEditSavedLabel(index);
                        }}
                    >
                        <EditIcon />
                    </button>
                    <button
                        className={button}
                        onClick={() => {
                            onRemoveSavedLabel(index);
                        }}
                    >
                        <RemoveIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};

function getCapacityName(capacity: string, t: TFunction<string | string[]>) {
    if (capacity === '500 ml lemon') {
        return t('label.creator.bottle.lemon');
    } else if (capacity === '100 ml') {
        return t('label.creator.bottle.small');
    } else {
        return capacity;
    }
}

export default SavedLabel;
