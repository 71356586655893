import React, { useRef, useMemo, useState, useEffect } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getImage } from 'gatsby-plugin-image';
import { Formik, FormikProps } from 'formik';
import { navigate } from 'gatsby';

import { wrapper, canvasLayout, canvas } from './label-generator.module.scss';

import {
    ICleanImage,
    ILabelStyleCreator,
    TLabelGeneratorCapacity,
} from '../../models/label-style.model';
import { IDirectories } from '../../models/directories.model';
import { ICreateLabelValues } from '../../models/create-form.model';
import { IWishes } from '../../models/wishes.model';
import { ILabelTexts } from '../../models/label-style.model';
import { getLabelFormSchema } from '../../formik/label-form.config';
import { drawLabel, getDrawnLabel, reDrawLabel } from '../../utils/label-generator-utils';

import FormLabel from '../atoms/form-label';
import LabelGeneratorForm from '../molecules/label-generator-form';
import ChangeStyleModal from './change-style-modal';
import LabelGeneratorSwitchOccasion from './label-generator-switch-occasion';

interface ILabelGenerator {
    savedLabels: ICreateLabelValues[];
    initialValues: ICreateLabelValues;
    onAddNewSavedLabel: (values: ICreateLabelValues, labelImage: string | undefined) => void;
    onHandleAddSavedLabel: () => void;
    onHandleCancelLabelForm: () => void;
    formikRef: React.Ref<FormikProps<ICreateLabelValues>>;
    labelStyles: ILabelStyleCreator[];
    saveSavedLabels: (newLabels: ICreateLabelValues[]) => void;
    wishes: IWishes[];
    occasion: string;
    directories: IDirectories[];
}

const LabelGenerator: React.FC<ILabelGenerator> = ({
    savedLabels,
    initialValues,
    onAddNewSavedLabel,
    onHandleAddSavedLabel,
    onHandleCancelLabelForm,
    formikRef,
    labelStyles,
    saveSavedLabels,
    wishes,
    occasion,
    directories,
}) => {
    const [capacityType, setCapacityType] = useState<TLabelGeneratorCapacity>('500 ml');
    const [cleanImages, setCleanImages] = useState<Record<string, ICleanImage>>();
    const { t } = useI18next();

    const labelFormSchema = useMemo(() => getLabelFormSchema(t), [t, occasion]);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const [modalIsActive, setModalIsActive] = useState(false);

    const [nextOccasion, setNextOccasion] = useState(occasion);
    const [imageDigits, setImageDigits] = useState<HTMLImageElement[] | undefined>();
    const [isCleanLabelsLoaded, setIsCleanLabelsLoaded] = useState(false);

    const labelTexts: ILabelTexts = {
        years: {
            displayName: `${t('label.text.years')}`,
            displayAltName: `${t('label.text.years.alternative')}`,
        },
        together: {
            displayName: `${t('label.text.together')}`,
        },
    };

    function closeModal() {
        setModalIsActive(false);
    }

    function handleChangeOccasion(occasionName: string) {
        setNextOccasion(occasionName);
        let deleteLabels = false;

        if (savedLabels) {
            savedLabels.forEach((label) => {
                if (label.occasion !== occasionName) {
                    setModalIsActive(true);
                    deleteLabels = true;
                    return false;
                }
            });
        }

        if (!deleteLabels) {
            const directory = directories.filter((directory) => directory.name === occasionName);
            navigate(directory[0].link);
        }
    }

    function getLabelsCount() {
        let count = occasion === 'wedding' ? labelStyles.length * 2 : labelStyles.length;

        labelStyles.forEach((label) => {
            if (label.lemonCleanLabelImage || label.mintCleanLabelImage) {
                count++;
            }
        });

        return count;
    }

    const numberOfLabelImages = getLabelsCount();

    let counter = 0;

    function incrementCounter(images: { [index: string]: ICleanImage }) {
        counter++;
        if (counter === numberOfLabelImages) {
            handleChange({ values: initialValues, cleanImagesSource: images });
            setIsCleanLabelsLoaded(true);
        }
    }

    useEffect(() => {
        const images: {
            [index: string]: ICleanImage;
        } = {};

        labelStyles.forEach((style) => {
            const image = new Image();
            const imageSrc = getImage(style.cleanLabelImage.localFile)?.images?.fallback?.src;

            let smallImage, smallImageSrc, lemonImage, lemonImageSrc, mintImage, mintImageSrc;

            if (style.smallCleanLabelImage) {
                smallImage = new Image();
                smallImageSrc = getImage(style.smallCleanLabelImage.localFile)?.images?.fallback
                    ?.src;
            }

            if (style.lemonCleanLabelImage) {
                lemonImage = new Image();
                lemonImageSrc = getImage(style.lemonCleanLabelImage.localFile)?.images?.fallback
                    ?.src;
            }

            if (style.mintCleanLabelImage) {
                mintImage = new Image();
                mintImageSrc = getImage(style.mintCleanLabelImage.localFile)?.images?.fallback?.src;
            }

            if (style.imageDigits?.length) {
                const digits = style.imageDigits.map((digit) => {
                    const img = new Image();
                    const digitSrc =
                        style.imageDigits &&
                        getImage(
                            style.imageDigits[style.imageDigits?.indexOf(digit)].localFile
                                .childrenImageSharp[0]
                        )?.images?.fallback?.src;
                    img.src = digitSrc ? digitSrc : '';
                    return img;
                });
                setImageDigits(digits);
            }

            if (imageSrc) {
                image.src = imageSrc;
                image.onload = () => {
                    incrementCounter(images);
                };
                images[style.type] = {
                    ...images[style.type],
                    image: image,
                };
            }

            if (smallImage && smallImageSrc) {
                smallImage.src = smallImageSrc;
                smallImage.onload = () => {
                    incrementCounter(images);
                };
                images[style.type] = {
                    ...images[style.type],
                    smallImage: smallImage,
                };
            }

            if (lemonImage && lemonImageSrc) {
                lemonImage.src = lemonImageSrc;
                lemonImage.onload = () => {
                    incrementCounter(images);
                };
                images[style.type] = {
                    ...images[style.type],
                    lemonImage: lemonImage,
                };
            }

            if (mintImage && mintImageSrc) {
                mintImage.src = mintImageSrc;
                mintImage.onload = () => {
                    incrementCounter(images);
                };
                images[style.type] = {
                    ...images[style.type],
                    mintImage: mintImage,
                };
            }
        });

        setCleanImages(images);
    }, []);

    function handleChange(props: {
        values: ICreateLabelValues;
        savedLabels?: ICreateLabelValues[];
        cleanImagesSource?: Record<string, ICleanImage> | undefined;
        setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    }) {
        const { values, savedLabels, cleanImagesSource = cleanImages, setFieldValue } = props;
        const foundLabel = labelStyles.find((style) => style.type === values.style);
        const foundCapacity = foundLabel?.capacities.find(
            (capacity) => capacity.capacity === values.capacity
        );

        if (
            savedLabels &&
            savedLabels.length &&
            values.style !== savedLabels[0].style &&
            occasion === 'wedding'
        ) {
            setModalIsActive(true);
            return false;
        }

        if (
            !values.secondLine.startsWith('& ') &&
            setFieldValue &&
            (values.occasion === 'wedding' ||
                values.occasion === 'anniversary' ||
                values.capacity === 'small')
        ) {
            let text = values.secondLine.substring(0, 3);
            text = text.replace('&', '');
            text = text.replace(' ', '');
            setFieldValue('secondLine', `& ${text}${values.secondLine.substring(3)}`);
        }

        if (foundCapacity) {
            if (
                capacityType !== foundCapacity.capacity &&
                occasion === 'birthday' &&
                setFieldValue &&
                (capacityType === '500 ml lemon' || foundCapacity.capacity === '500 ml lemon')
            ) {
                setFieldValue('date', '');
            }

            setCapacityType(foundCapacity.capacity);
        }

        if (foundLabel) {
            drawLabel({
                cleanImages: cleanImagesSource,
                values: values,
                occasion: occasion,
                canvasRef: canvasRef,
                savedLabel: foundLabel,
                isSmall: !foundCapacity,
                capacityType: capacityType,
                labelTexts: labelTexts,
                imageDigits: imageDigits,
                isImageLoaded: isCleanLabelsLoaded,
            });
        }

        return false;
    }

    return (
        <div className={wrapper}>
            <div className={canvasLayout}>
                <FormLabel labelText={t('label.creator.image.label')} />
                <canvas ref={canvasRef} className={canvas} width="494" height="670" />
            </div>
            <div>
                <Formik
                    innerRef={formikRef}
                    enableReinitialize={true}
                    validationSchema={labelFormSchema}
                    initialValues={initialValues}
                    validateOnMount={true}
                    onSubmit={(values, actions) => {
                        onAddNewSavedLabel(values, getDrawnLabel(canvasRef));
                        actions.setSubmitting(false);
                        actions.resetForm({
                            values: initialValues,
                        });
                    }}
                >
                    {({ values }) => {
                        return (
                            <>
                                {modalIsActive && canvasRef.current && (
                                    <ChangeStyleModal
                                        closeModal={closeModal}
                                        savedLabels={savedLabels}
                                        saveSavedLabels={saveSavedLabels}
                                        cleanImages={cleanImages}
                                        canvasRef={canvasRef.current}
                                        labelStyles={labelStyles}
                                        reDrawLabel={reDrawLabel}
                                        occasion={occasion}
                                        directories={directories}
                                        nextOccasion={nextOccasion}
                                        setNextOccasion={setNextOccasion}
                                        labelTexts={labelTexts}
                                        imageDigits={imageDigits}
                                        isImageLoaded={isCleanLabelsLoaded}
                                    />
                                )}
                                <LabelGeneratorSwitchOccasion
                                    handleChangeOccasion={handleChangeOccasion}
                                    currentOccasion={occasion}
                                    directories={directories}
                                />
                                <LabelGeneratorForm
                                    values={values}
                                    handleChange={handleChange}
                                    handleLabelSave={onHandleAddSavedLabel}
                                    handleLabelCancel={onHandleCancelLabelForm}
                                    labelStyles={labelStyles}
                                    savedLabels={savedLabels}
                                    wishes={wishes}
                                    occasion={occasion}
                                />
                            </>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default LabelGenerator;
