import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { getLabelsCampaignSettings } from '../api-ssr/label-styles';

export function useCampaignSettings() {
    const [minDate, setMinDate] = useState(new Date());

    const checkCampaignSettings = useMutation(getLabelsCampaignSettings, {
        onSuccess: ({ data }) => {
            if (data !== null) {
                setMinDate(() => {
                    const date = new Date();
                    date.setDate(date.getDate() + data.serviceDays);
                    return date;
                });
            }
        },
    });

    useEffect(() => {
        checkCampaignSettings.mutate();
    }, []);

    return {
        minDate,
    };
}
